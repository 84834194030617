import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TopicsService } from '../../../../@core/data/topics.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import Utils from '../../../../@core/utils/utils';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngx-add-topic',
  templateUrl: './add-topic.component.html',
})
export class AddTopicComponent implements OnInit {

  constructor(
    private topicsService: TopicsService,     
    private router: Router,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<AddTopicComponent>,) {
  }

  ngOnInit() {
  }

  onSubmit(form: FormGroup) {
    const topic = form.getRawValue();
    Utils.removeEmptyFields(topic);
    this.topicsService.addTopic(topic).subscribe(res => {
      this.toaster.success('Topic successfully created!');
      if (!!this.dialogRef.componentInstance){
        // console.log('open from dialog');
        this.dialogRef.close(res);
      } else{
        this.router.navigate(['/pages/education-administration/topics']);
      }
      }, error => this.toaster.error(error));
  }

  close() {
    this.dialogRef.close();
  }

  get componentInstance() { return this.dialogRef.componentInstance }
}
