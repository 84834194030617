import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {

  constructor() {
  }

  handleHttpError(error: any) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = 'An error occurred:' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
      if (error.status === 400) {
        errorMessage = error.error.message ? error.error.message : 'Bad Request';
      } else if (error.status === 401) {
        errorMessage = 'You are unauthorised to do so.';
      } else if (error.status === 500) {
        errorMessage = 'Internal service error, please try again later.';
      } else if (error.status === 404) {
        errorMessage = '404 Not Found';
      } else if (error.status === 413) {
        errorMessage = 'File size too large';
      } else if (error.name === 'TimeoutError') {
        errorMessage = 'Upload timeout, please check your internet connection and try again later';
      } else {
        if (error.error.message) {
          errorMessage = error.error.message
        } else {
          errorMessage = 'Unknown server error, please try again later.';
        }

      }
    }
    // return an observable with a user-facing error message
    return throwError(errorMessage);
  }
}
