import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TitleToAliasPipe } from '../../../../../app/@theme/pipes/titleToAlias.pipe';
import { ImagesService } from '../../../../@core/data/images.service';

@Component({
    selector: 'ngx-topic-form',
    templateUrl: './topic-form.component.html',
    providers: [TitleToAliasPipe],
    standalone: false
})
export class TopicFormComponent implements OnInit, OnChanges {
  @Input() topic: any;
  @Output() formSubmit: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  topicForm: FormGroup;
  submitted = false;
  isEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private imagesService: ImagesService,
    private titleToAlias: TitleToAliasPipe,
  ) {
  }

  ngOnInit() {
    if (this.topic) {
    } else {
      this.topicForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        isActive: [true, Validators.required],
        isEducatorTopic: [true, Validators.required],
        dcCoverImageURL: [''],
        alias: ['']
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.topic) {
      this.isEdit = true;
      this.topicForm = this.formBuilder.group({
        name: [this.topic.name, Validators.required],
        description: [this.topic.description ? this.topic.description : ''],
        isActive: [this.topic.isActive, Validators.required],
        isEducatorTopic: [this.topic.isEducatorTopic, Validators.required],
        dcCoverImageURL: [this.topic.dcCoverImageURL || ''],
        alias: [this.topic.alias || '', Validators.required]
      });
    }
  }

  get f() {
    return this.topicForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.topicForm.invalid) {
      return;
    } else {
      this.formSubmit.emit(this.topicForm);
    }
  }

  get dcCoverImageURL(): string {
    return this.topicForm.controls.dcCoverImageURL.value || '';
  }

  fileSelected(imageURL) {
    imageURL.subscribe(url => this.topicForm.get('dcCoverImageURL').setValue(url));
  }

  setAlias() {
    this.topicForm.patchValue({
      alias: this.titleToAlias.transform(this.topicForm.value.name),
  });
  }
}
