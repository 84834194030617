import { Component } from '@angular/core';

@Component({
    selector: 'ngx-footer',
    styleUrls: ['./footer.component.scss'],
    template: `
    <span class="created-by">© 2023 Ausmed Education Pty Ltd (ABN: 33 107 354 441)</span>
  `,
    standalone: false
})
export class FooterComponent {
}
