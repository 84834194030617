import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ImagesService } from '../../../@core/data/images.service';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

@Component({
    selector: 'ngx-ausmed-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ImagesService],
    standalone: false
})
export class ImageUploadComponent implements OnDestroy {
  @Input() imageType: string;
  @Output() uploadImage: EventEmitter<Observable<string>> = new EventEmitter<Observable<string>>();
  @Input() imageURL: string;
  @Input() labelText = 'Image URL';
  @Input() labelClassNames: string[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  uniqID = Math.random().toString(36).substr(2, 5);
  @Input() hasError: any = false;

  constructor(private imagesService: ImagesService, private cdr: ChangeDetectorRef) { }

  onFileSelected(event): void {
    this.uploadImage.emit(this.imagesService.processFile(event.target.files[0], this.imageType)
      .pipe(takeUntil(this.ngUnsubscribe), filter(r => !!r), map(res => res.url)));
  }

  onPasteURL(event) {
    this.uploadImage.emit(of(event.target.value).pipe(takeUntil(this.ngUnsubscribe), tap(() => this.cdr.detectChanges())));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
