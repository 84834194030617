import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { ThemeModule } from '../@theme/theme.module';
import { RouterModule } from '@angular/router';
import { NbAccordionModule, NbSpinnerModule } from '@nebular/theme';
import { SearchUserModalComponent } from './search-user-modal/search-user-modal.component';
import { ValidateHTMLDirective } from './directives/validate-html.directive';
import { NgxEditorRegisterDirective } from './directives/ngx-editor-register.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TagInputModule } from '../../app/shared/modules/ngx-chips';


// Angular Material Components
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { ImageUploadComponent } from './reusable-components/image-upload/image-upload.component';
import { AddNewTopicComponent } from './reusable-components/add-new-topic/add-new-topic.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Ng2SmartTableModule } from './modules/ng2-smart-table/ng2-smart-table.module';
import { StandardsMultiSelectComponent } from './components/standards-multi-select/standards-multi-select.component';
import { ThemeColorDisplayNamePipe } from './pipes/theme-color-display-name.pipe';
import { ThemeButtonDisplayNamePipe } from './pipes/theme-button-display-name.pipe';

@NgModule({
  declarations: [
    SearchUserModalComponent,
    ValidateHTMLDirective,
    SafeHtmlPipe,
    ImageUploadComponent,
    NgxEditorRegisterDirective,
    AddNewTopicComponent,
    ThemeColorDisplayNamePipe,
    ThemeButtonDisplayNamePipe,
  ],
  imports: [
    CommonModule,
    ThemeModule,
    RouterModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgSelectModule,
    NbSpinnerModule,
    // NgxCurrencyModule,
    NbAccordionModule,
    TagInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTableModule,
    NgxSpinnerModule,
    StandardsMultiSelectComponent
  ],
  providers: [
  ],
  exports: [
    CommonModule,
    ThemeModule,
    RouterModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgSelectModule,
    NbSpinnerModule,
    // NgxCurrencyModule,
    NbAccordionModule,
    TagInputModule,
    ValidateHTMLDirective,
    SafeHtmlPipe,
    MatCheckboxModule,
    MatTabsModule,
    MatTableModule,
    ImageUploadComponent,
    AddNewTopicComponent,
    NgxEditorRegisterDirective,
    NgxSpinnerModule,
    StandardsMultiSelectComponent,
    ThemeColorDisplayNamePipe,
    ThemeButtonDisplayNamePipe,
  ],
})
export class SharedModule { }

