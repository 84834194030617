import { Injectable } from '@angular/core';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { IContactEmail } from './app-data-sharing.service';

@Injectable()
export class UserInfoService {

  selectedUser;
  preferenceOptions = [
    {
      title: 'Daily CPD Alerts',
      checked: false,
      value: 'onlineCpdAlerts',
      desc: 'Daily emails about our latest and most popular online courses, video lectures and articles.',
    },
    {
      title: 'Weekly Events Alerts',
      checked: false,
      value: 'eventWeeklyAlerts',
      desc: 'Weekly alerts about upcoming conferences and seminars taking place near you.',
    },
    {
      title: 'The Handover',
      checked: false,
      value: 'handover',
      desc: 'The Handover is delivered to your inbox every Saturday and is best read with your morning cuppa. A recap of the best CPD of the past week.',
    },
  ];


  constructor(
    protected http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  getData(includingContactEmail?: boolean): Observable<any> {
    let url;
    if (includingContactEmail) {
      url = environment.accountServiceEndpoint + '/admin/users?includingContactEmail=true';
    } else {
      url = environment.accountServiceEndpoint + '/admin/users';
    }

    return this.http.get(url);
  }

  getUser(id): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/users/${id}`;
    return this.http.get(url).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  createUser(user) {
    return this.http.post(environment.accountServiceEndpoint + '/signUp', user)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  searchUser(user, includingContactEmail?: boolean): Observable<any> {
    const url = environment.accountServiceEndpoint + '/admin/users';
    let searchParams = new HttpParams({ encoder: new CustomEncoder() });
    searchParams = searchParams.append('firstName', user.firstName);
    searchParams = searchParams.append('lastName', user.lastName);
    searchParams = searchParams.append('username', user.username);
    searchParams = searchParams.append('email', user.email);
    searchParams = searchParams.append('phone', user.phone || '');
    searchParams = searchParams.append('mobile', user.mobile || '');
    searchParams = searchParams.append('limit', '100');
    searchParams = searchParams.append('offset', '0');
    if (includingContactEmail) {
      searchParams = searchParams.append('includingContactEmail', 'true');
    }

    const httpOptions = {
      params: searchParams,
    };
    return this.http.get(url, httpOptions).pipe(map((users: any[]) => {
      return users.map((u: any) => {
        u['haveAccount'] = u.username ? 'Yes' : 'No';
        return u;
      });
    }));
  }

  searchExactUser(user, includingContactEmail?: boolean): Observable<any> {
    const url = environment.accountServiceEndpoint + '/admin/exactUsers';
    let searchParams = new HttpParams({ encoder: new CustomEncoder() });
    searchParams = searchParams.append('firstName', user.firstName);
    searchParams = searchParams.append('lastName', user.lastName);
    searchParams = searchParams.append('username', user.username);
    searchParams = searchParams.append('email', user.email);
    searchParams = searchParams.append('phone', user.phone || '');
    searchParams = searchParams.append('mobile', user.mobile || '');
    searchParams = searchParams.append('limit', '100');
    searchParams = searchParams.append('offset', '0');
    if (includingContactEmail) {
      searchParams = searchParams.append('includingContactEmail', 'true');
    }

    const httpOptions = {
      params: searchParams,
    };
    return this.http.get(url, httpOptions).pipe(map((users: any[]) => {
      return users.map((u: any) => {
        u['haveAccount'] = u.username ? 'Yes' : 'No';
        return u;
      });
    }));
  }

  updateSelectedUser(user): void {
    this.selectedUser = user;
  }

  updateUser(user): Observable<any> {
    // TODO: it should update selected user as a side effect ?
    const url = environment.accountServiceEndpoint + `/admin/users/${user.userID}`;
    return this.http.put(url, user);
  }

  getSyncUser(id): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/sync/user/${id}`;
    return this.http.get(url);
  }

  getSignInAsCustomerToken(username: string): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/signInAsCustomer?username=${username}`;
    return this.http.get(url);
  }

  linkUser(userID, username): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/sync/linkUser/${userID}`;
    return this.http.post(url, username);
  }

  getUserByUsername(username): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/users/byUsername/${username}`;
    return this.http.get(url);
  }

  searchUserByEmail(email): Observable<any> {
    const url = environment.accountServiceEndpoint + '/admin/exactUsers';
    let searchParams = new HttpParams();
    searchParams = searchParams.append('email', email);
    const httpOptions = {
      params: searchParams,
    };

    return this.http.get(url, httpOptions);
  }

  getContact(contactID: string): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/contactEmails/${contactID}`;
    return this.http.get(url).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  createContact(contact: IContactEmail): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/contactEmails`;
    return this.http.post(url, contact).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  updateContact(contact: IContactEmail): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/contactEmails/${contact.userID}`;
    return this.http.put(url, contact).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  public getPreferenceOptions(): any[] {
    return this.preferenceOptions.slice(0);
  }

  public sendContactEmailTokenEmail(userID: string): Observable<any> {
    return this.http.get(`${environment.accountServiceEndpoint}/contactEmails/${userID}/sendToken`);
  }

  public processOption(contact, options): any[] {
    if (contact.preferences && contact.isActive) {
      options.forEach(option => {
        option.checked = contact.preferences.includes(option.value);
      });
    }
    return options;
  }
  
  sendResetPasswordEmail(email: string) {
    return this.http.post(environment.accountServiceEndpoint + '/reset', {email});
  }
}


// Encoder added for search query
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

