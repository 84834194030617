import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import Utils from '../../@core/utils/utils';

@Directive({
  selector: '[validateHTML][ngModel]',
  providers: [{
    provide: NG_VALIDATORS, useExisting: ValidateHTMLDirective, multi: true,
  }],
})
export class ValidateHTMLDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = Utils.validateHtmlFactory();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validator(control);
  }

}
