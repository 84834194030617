import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleToAlias',
    standalone: false
})
export class TitleToAliasPipe implements PipeTransform {

  transform(input: string): string {
    return input ? String(input).toLowerCase().replace(/(&#)\d\d\d\d(;)/g, ' ').replace(/[^a-zA-Z0-9]+/g, ' ').trim().replace(/\s+/g, '-') : '';
  }
}
