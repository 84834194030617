import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  statesDict = {
    VI: 'VIC',
    QL: 'QLD',
    NW: 'NSW',
    SA: 'SA',
    WA: 'WA',
    AT: 'ACT',
    NT: 'NT',
    TA: 'TAS',
  };

  transform(value: any, ...args: any[]): any {
    return this.statesDict[value];
  }

}
