import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventType',
    standalone: false
})
export class EventTypePipe implements PipeTransform {
  dict = {
    localConference: 'Local Conference',
    nationalConference: 'National Conference',
    seminar: 'Seminar',
    holiday: 'Holiday',
  };

  transform(value: any, ...args: any[]): any {
    return value ? this.dict[value] : '';
  }

}
