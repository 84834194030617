import { Component } from '@angular/core';

import { DefaultEditor } from './default-editor';
import { NgClass, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'select-editor',
  template: `
    <select [ngClass]="inputClass"
            class="form-control"
            [(ngModel)]="cell.newValue"
            [name]="cell.getId()"
            [disabled]="!cell.isEditable()"
            (click)="onClick.emit($event)"
            (keydown.enter)="onEdited.emit($event)"
            (keydown.esc)="onStopEditing.emit()">

            @for( option of cell.getColumn().getConfig()?.list; track option){
              <option [value]="option.value"
                [selected]="option.value === cell.getValue()">{{ option.title }}
        </option>
            }
        
    </select>
    `,
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgFor,
  ],
})
export class SelectEditorComponent extends DefaultEditor {

  constructor() {
    super();
  }
}
