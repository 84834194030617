import { Directive, Input, SimpleChange } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgxEditorComponent } from '../../shared/ngx-editor/ngx-editor.component';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { fromProperty$ } from '../../../app/utils/rx/from-property';

@Directive({
  selector: '[ngxEditorRegister]'
})
export class NgxEditorRegisterDirective {

  @Input() ngxEditorRegister: NgModel;
  @Input() contentEditor: NgxEditorComponent;

  fromModel$ = fromProperty$(this, `ngxEditorRegister`).pipe(filter(x => !!x), take(1));

  fromEditor$ = fromProperty$(this, `contentEditor.ngxWrapper`).pipe(
    filter(x => !!x),
    take(1),
  );

  constructor() {
    combineLatest([this.fromModel$, this.fromEditor$]).subscribe(
      ([model,]) => {
        const ngModel: NgModel = model;
        if (ngModel.pristine) {
          const change = new SimpleChange(null, ngModel.model, true);
          ngModel.ngOnChanges({ model: change });
        }
      }
    );
  }

}
