<div *ngIf="uniqID" class="row">
    <div class="col-12 form-group">
        <label [ngClass]="labelClassNames" [for]="'imageURL'+ uniqID"> {{ labelText }} </label>
        <input [class.form-control-danger]="!!hasError" type="text" class="form-control" [id]="'imageURL'+ uniqID"
            [(ngModel)]="imageURL" (change)="onPasteURL($event)" />
        <div class="mt-2">
            <label [for]="'file-upload'+ uniqID" class="custom-file-upload">
                <input type="file" [id]="'file-upload'+ uniqID" (change)="onFileSelected($event)" accept="image/*" />
                <i class="fa fa-cloud-upload"></i> Upload Image
            </label>
        </div>
    </div>

    <div class="col-12" *ngIf="imageURL">
        <img class="img-thumbnail img-fluid" src="{{ imageURL }}" alt="upload image preview" />
    </div>
</div>