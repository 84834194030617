import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import Utils from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {

  DIMENSIONS = {
    'eventCoverImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-event/' },
    'conferenceBannerImage': { width: 1600, height: 400, path: 'ausmed-images/ausmed.com/ausmed-event/' },
    'educatorProfileImage': { width: 400, height: 400, path: 'ausmed-images/ausmed.com/ausmed-educator/' },
    'lectureImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-lecture/' },
    'courseImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-course/' },
    'articleImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-article/' },
    'onlineEducationBannerImage': { width: 1200, height: 617, path: 'ausmed-images/ausmed.com/features/' },
    'thirdPartyResourceImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-third-party/' },
    'explainerImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-explainer/' },
    'skillTrackImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-skill-track/' },
    guideToPracticeImage: { width: 1024, height: 576, path: 'ausmed-images/ausmed.com/ausmed-guides/' },
    topicsImage: { width: 200, height: 200, path: 'ausmed-images/ausmed.com/ausmed-topics/' },
    thirdPartyLogo: { width: 600, height: 600, path: 'ausmed-images/ausmed.com/ausmed-third-party/' },
    eventBannerImage: { width: 1200, height: 400, path: 'ausmed-images/ausmed.com/event-site-banners/' },
    hubSubImage: { width: 500, height: 500, path: 'ausmed-images/ausmed.com/ausmed-hubs/' },
    hubThumbnailImage: { width: 1700, height: 800, path: 'ausmed-images/ausmed.com/ausmed-hubs/' },
    hubBannerImage: { width: 1700, height: 800, path: 'ausmed-images/ausmed.com/ausmed-hubs/' },
    mindfulnessImage: { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-mindfulness/' },
    recipeImage: { width: 640, height: 360, path: 'ausmed-images/ausmed.com/ausmed-recipe/' },
    bannerImageURL: { path: 'ausmed-images/ausmed.com/ausmed-third-party/' },
    'trainingPlanTemplateImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/training-plan-templates/' },
    'contentBundleImage': { width: 640, height: 360, path: 'ausmed-images/ausmed.com/content-bundles/' },
  };

  constructor(
    protected http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  uploadImage(file: any, type): Observable<any> {
    const imageName = Utils.RemoveSuffix(file.name);
    const { width, height, path } = this.DIMENSIONS[type];
    var url;
    if (!!width && !!height) {
      url = `${environment.uploadServiceEndpoint}/covers/${imageName}?width=${width}&height=${height}&path=${path}`;
    } else {
      url = `${environment.uploadServiceEndpoint}/covers/${imageName}?&path=${path}`;
    }
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData).pipe(
      timeout(60000),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  processFile(file: any, type: string): Observable<any> {
    if (!file) {
      return of(null);
    }
    if (file && file.size >= 10485760) {
      alert('The document size should be under 10MB');
      return of(null);
    }

    // check naming rule: no capitals, no special characters, no spaces
    // eventname-year-cover
    if (type === 'eventCoverImage') {
      const imageName = Utils.RemoveSuffix(file.name),
        coverNameRegex = /^[0-9a-z]+-[0-9]+-cover$/;
      if (!coverNameRegex.test(imageName)) {
        alert('The file name should follow the naming rules: eventname-year-cover. No capitals, no special characters, no spaces.');
        return of(null);
      }
    }

    // check naming rule: no capitals, no special characters, no spaces
    // eventname-year-banner
    if (type === 'conferenceBannerImage') {
      const imageName = Utils.RemoveSuffix(file.name),
        coverNameRegex = /^[0-9a-z]+-[0-9]+-banner$/;
      if (!coverNameRegex.test(imageName)) {
        alert('The file name should follow the naming rules: eventname-year-banner. No capitals, no special characters, no spaces.');
        return of(null);
      }
    }

    return this.uploadImage(file, type);
  }
}
