<form [formGroup]="topicForm" (ngSubmit)="onSubmit()">
    <div class="row mb-2">
        <div class="col-6">
            <div class="form-group">
                <label>Name*</label>
                <input class="form-control" id="name" type="text" name="name" formControlName="name">
                <div class="text-danger" *ngIf="(submitted || !f.name.pristine) && f.name.invalid">
                    <small *ngIf="f.name.errors.required">Name is required</small>
                </div>
            </div>
            <div class="form-group">
                <label for="alias">Alias* (DO NOT contain any '/' in alias)</label>
                <span *ngIf="!topicForm.value.title" class="ms-2">{{ topicForm.value.name | titleToAlias }}
                    <button type="button" (click)="setAlias()" class="btn btn-primary btn-sm">
                        use it
                    </button>
                </span>
                <input class="form-control pt-2" id="alias" type="text" name="alias" formControlName="alias">
                <div class="text-danger" *ngIf="(submitted || !f.alias.pristine) && f.alias.invalid">
                    <small *ngIf="f.alias.errors.required">Alias is required</small>
                </div>
            </div>
            <div class="form-group">
                <label for="description">Description</label>
                <textarea [rows]="4" class="form-control" id="description" name="description"
                    formControlName="description"></textarea>
            </div>
        </div>
        <div class="col-6">
            <ngx-ausmed-image-upload [imageURL]="dcCoverImageURL" imageType='topicsImage'
                (uploadImage)="fileSelected($event)"></ngx-ausmed-image-upload>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="isActive" formControlName="isActive">
                <label class="form-check-label" for="isActive">Is Active</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="isEducatorTopicId"
                    formControlName="isEducatorTopic">
                <label class="form-check-label" for="isEducatorTopicId">Educator</label>
            </div>
        </div>
    </div>

    <button nbButton status="info" type="submit" [disabled]="topicForm.invalid">{{isEdit ? 'Update' : 'Add'}}</button>
</form>
