import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: NbAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let interceptedReq: HttpRequest<any> = null;
    this.auth.getToken().subscribe(token => {
      interceptedReq = request.clone({
        headers: request.headers.set('X-Auth-Token', token.toString()),
      });
    });

    this.auth.getToken().subscribe(token => {
      interceptedReq = request.url.indexOf('getvero') === -1 ? request.clone({
        headers: request.headers.set('X-Auth-Token', token.toString()),
      }) : request.clone({
        headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded'),
      });
    });

    return next.handle(interceptedReq);

  }
}
