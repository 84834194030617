import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { rest, first } from 'underscore';

function _fromProperty$(target: Object, key: string): Observable<any> {
    const sbj = new BehaviorSubject(target[key]);
    Object.defineProperty(target, key, {
        get: function () { return sbj.value; },
        set: function (v) { return sbj.next(v); },
        enumerable: true,
    });
    return sbj.asObservable();
}

/**
 * creates an observable based on an object property. Emits the initial value and any new value set
 * @param target object whose property needs to be observed
 * @param path key of property that needs to be observed
 */
export function fromProperty$(target: Object, path: string): Observable<any> {
    const keys = path.split('.');
    if (keys.length <= 0) {
        return throwError(`invalid path`);
    }
    if (keys.length === 1) {
        const key = first(keys);
        return _fromProperty$(target, key);
    } else {
        const key = first(keys);
        return _fromProperty$(target, key).pipe(
            filter(x => !!x),
            switchMap(obj => fromProperty$(obj, rest(keys).join('.')))
        );
    }
}
