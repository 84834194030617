import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ErrorHandlingService } from './error-handling.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TopicsService {

  selectedTopic: any = {};

  constructor(
    protected http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  getTopics(): Observable<any> {
    const url = environment.resourceServiceReadEndpoint + '/topics';
    return this.http.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getTopicByID(topicID): Observable<any> {
    const url = `${environment.resourceServiceEndpoint}/topics/${topicID}`;
    return this.http.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }
  addTopic(topic): Observable<any> {
    const url = environment.resourceServiceEndpoint + '/topics';
    return this.http.post(url, topic).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  updateTopic(topicID, topic): Observable<any> {
    const url = `${environment.resourceServiceEndpoint}/topics/${topicID}`;
    return this.http.put(url, topic).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getResourcesByTopic(topicID): Observable<any> {
    const url = `${environment.resourceServiceEndpoint}/resources/topics/${topicID}/admin`;
    return this.http.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteTopic(topicID): Observable<any> {
    const url = `${environment.resourceServiceEndpoint}/topics/${topicID}`;
    return this.http.delete(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }
}

