import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddTopicComponent } from '../../../../app/pages/education-administration/topics/add-topic/add-topic.component';

@Component({
    selector: 'add-new-topic',
    templateUrl: './add-new-topic.component.html',
    standalone: false
})
export class AddNewTopicComponent implements OnInit {

  @Output() newTopic = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  addNewTopic(){
    // console.log('addTopic');
    const dialogRef = this.dialog.open(AddTopicComponent, {
      height: '600px',
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (!!result){
        this.newTopic.emit(result);
      }
    });
  }

}
