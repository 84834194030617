
<nb-card>
  <nb-card-header>
    Add New Topic
    <button class="btn-close" aria-label="Close" (click)="close()" *ngIf="!!componentInstance">
      
    </button>
  </nb-card-header>
  <nb-card-body>
    <ngx-topic-form (formSubmit)="onSubmit($event)"></ngx-topic-form>
  </nb-card-body>
</nb-card>
