
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {tap} from 'rxjs/operators';


let counter = 0;

@Injectable({providedIn: 'root'})
export class UserService {

  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };

  adminUserName = '';
  adminUserGroupId = '';
  adminUserGroupName = '';
  adminUserPermissions = [];
  private userArray: any[];

  constructor(protected http: HttpClient) {
    // this.userArray = Object.values(this.users);
  }

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return observableOf(this.userArray[counter]);
  }

  getUserProfile(): Observable<any> {
    const url = environment.accountServiceEndpoint + '/user';
    return this.http.get(url).pipe(tap(({username, adminGroup}) => {
      this.adminUserName = username;
      this.adminUserGroupId = adminGroup.adminGroupID;
      this.adminUserGroupName = adminGroup.name;
      this.adminUserPermissions = adminGroup.permissions;
    }));
  }

  updateUserProfile(profile): Observable<any> {
    const url = environment.accountServiceEndpoint + '/user';
    return this.http.post(url, profile);
  }

  logout(): Observable<any> {
    const url = environment.accountServiceEndpoint + '/signOut';
    return this.http.get(url, {responseType: 'text'});
  }

  searchUserByUsername(searchedUsername): Observable<any> {
    const url = environment.accountServiceEndpoint + '/admin/users/byUsername/' + searchedUsername;
    return this.http.get(url);
  }

  getUserByID(userID): Observable<any> {
    const url = environment.accountServiceEndpoint + '/admin/users/' + userID;
    return this.http.get(url);
  }

  // this API also need admin token with customer_view access
  getStripeUserInfo(userID: string): Observable<any> {
    const url = environment.accountServiceEndpoint + `/admin/stripeUsers/${userID}`;
    return this.http.get(url);
  }

  saveCardToStripe(userID, sourceID): Observable<any> {
    return this.http.post(`${environment.accountServiceEndpoint}/admin/stripeUsers/${userID}/sources`, {'source': sourceID});
  }

  deleteCardFromStripe(userID, sourceID): Observable<any> {
    return this.http.delete(`${environment.accountServiceEndpoint}/admin/stripeUsers/${userID}/sources/${sourceID}`);
  }
}
