import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({providedIn: 'root'})
export class AppDataSharingService {
  private subject = new BehaviorSubject<any>(null);
  private recentCustomers = new BehaviorSubject<ICustomerInteraction[]>(null);
  private transferRego = new BehaviorSubject<any>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {

    if (isPlatformBrowser(platformId)) {
      const _recentCustomers = localStorage.getItem('recentCustomers');
      const recentCustomers = _recentCustomers && JSON.parse(_recentCustomers);
      if (recentCustomers) {
        this.recentCustomers.next(recentCustomers);
      }
    }
  }

  sendMessage(message: {}) {
    this.subject.next(message);
  }

  clearMessage() {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  addToRecentCustomers(customer: ICustomerInteraction) {
    let customers = JSON.parse(localStorage.getItem('recentCustomers') || '[]');

    if (!customers.find(c => c.userID === customer.userID)) {
      customers.push(customer);
    }
    if (customers.length > 10) {
      customers = customers.slice(1);
    }
    this.recentCustomers.next(customers);
    localStorage.setItem('recentCustomers', JSON.stringify(customers));
  }

  getRecentCustomers() {
    return this.recentCustomers.asObservable();
  }

  setTransferRego(rego) {
    this.transferRego.next(rego);
  }

  getTransferRego(): Observable<any> {
    return this.transferRego.asObservable();
  }

  clearTransferRego() {
    this.transferRego.next(null);
  }
}

export interface ICustomerInteraction {
  userID: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  lastInteraction: Date;
}

export interface IContactEmail {
  email: string;
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  authToken?: string;
  preferences?: string[];
  labels?: string[];
  userID?: string;
  isVerified?: boolean;
  verifiedDate?: string;
}
