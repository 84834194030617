import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { difference, flatten, intersection, sortBy } from 'underscore';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'standards-multi-select',
    templateUrl: 'standards-multi-select.component.html',
    styleUrl: 'standards-multi-select.component.scss',
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgForOf]
})

export class StandardsMultiSelectComponent implements OnInit, OnChanges {
    @Input() guidelines: any[];
    @Input() selectedGuidelines: any[] = [];
    @Output() guidelineSelected: EventEmitter<any[]> = new EventEmitter<any[]>();
    allGuidelines: any[] = [];

    ngOnInit() {
        this.reorderGuidelines(this.guidelines);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.guidelines?.currentValue) {
            this.reorderGuidelines(this.guidelines);
        }
    }

    private reorderGuidelines(guidelines) {
        const allGuidelines = sortBy(guidelines, 'provider', 'name');
        const standards = allGuidelines.filter(g => g.guidelineType === 'standard') || [];
        const outcomes = [...(allGuidelines.filter(g => g.guidelineType === 'outcome' && g.parentGuidelineID) || [])].map((outcome) => {
            outcome.parentGuideline = standards.find(s => s.guidelineID === outcome.parentGuidelineID);
            return outcome;
        });
        let sortedGuilines: any[] = [];
        standards.forEach((s: any) => {
            sortedGuilines.push(s);
            sortedGuilines.push(outcomes.filter(g => g.parentGuidelineID === s.guidelineID));
        });
        this.allGuidelines = flatten(sortedGuilines);
    }

    addAnItem(guideline) {
        if (guideline.parentGuidelineID && guideline.parentGuideline && !this.selectedGuidelines.find(g => g.guidelineID === guideline.parentGuidelineID)) {
            this.selectedGuidelines = [...this.selectedGuidelines, guideline.parentGuideline];
            // this.allGuidelines = this.allGuidelines.map(g => {
            //     if (g.guidelineID === guideline.parentGuidelineID) {
            //         g.disabled = true;
            //     }
            //     return g;
            // });
            this.guidelineSelected.emit(this.selectedGuidelines);
        }
    }

    removeAnItem(guideline) {
        if (guideline.guidelineType === 'standard' && intersection(this.selectedGuidelines, this.allGuidelines.filter(g => g.parentGuidelineID === guideline.guidelineID)).length > 0) {
            const outcomes = this.allGuidelines.filter(g => g.parentGuidelineID === guideline.guidelineID) || [];
            this.selectedGuidelines = [...difference(this.selectedGuidelines, outcomes)];
            this.guidelineSelected.emit(this.selectedGuidelines);
        }
    }

}