<div class="modal-header">
  <span>Search User</span>
  <button class="btn-close" aria-label="Close" (click)="closeModal()">
      
  </button>
</div>
<div class="modal-body">
  <div class="row">
      <div class="col-sm-6">
          <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="user.firstName" (keydown.enter)="searchUser()">
          </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" class="form-control" id="lastName" [(ngModel)]="user.lastName" (keydown.enter)="searchUser()">
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
      <label for="username">Username</label>
      <input type="text" class="form-control" id="username" [(ngModel)]="user.username" (keydown.enter)="searchUser()">
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
      <label for="email">Email</label>
      <input type="text" class="form-control" id="email" [(ngModel)]="user.email" (keydown.enter)="searchUser()">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
        <div class="form-group">
        <label for="mobile">Mobile</label>
        <input type="text" class="form-control" id="mobile" [(ngModel)]="user.mobile" (keydown.enter)="searchUser()">
        </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
      <label for="phone">Phone</label>
      <input type="text" class="form-control" id="phone" [(ngModel)]="user.phone" (keydown.enter)="searchUser()">
      </div>
  </div>
</div>
<div class="modal-footer">
<button (click)="searchUser()" class="btn btn-info">Search</button>
</div>
