<nb-auth-block>
  <h2 class="title">Sign In</h2>
  <small class="form-text sub-title">Hello! Sign in with your username</small>
  <form (ngSubmit)="login()" #form="ngForm" autocomplete="nope">
    <div *ngIf="showMessages.error && errors && errors.length > 0 && !submitted" class="alert alert-danger" role="alert">
      <div>
        <strong>Oh snap!</strong>
      </div>
      <div *ngFor="let error of errors">{{ error }}</div>
    </div>
    <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success" role="alert">
      <div>
        <strong>Hooray!</strong>
      </div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>
    <div class="form-group">
      <label for="input-username" class="sr-only">Username</label>
      <input name="username" [(ngModel)]="user.username" id="input-username" class="form-control" placeholder="username"
        #username="ngModel" [class.form-control-danger]="username.errors?.required && username.touched" autofocus [required]="getConfigValue('forms.validation.username.required')">
      <small class="form-text error" *ngIf="username.invalid && username.touched && username.errors?.required">
        Username is required!
      </small>
    </div>
    <div class="form-group">
      <label for="input-password" class="sr-only">Password</label>
      <input name="password" [(ngModel)]="user.password" type="password" id="input-password" class="form-control" placeholder="Password"
        #password="ngModel" [class.form-control-danger]="password.errors?.required && password.touched" [required]="getConfigValue('forms.validation.password.required')"
        [minlength]="getConfigValue('forms.validation.password.minLength')" [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
        Password is required!
      </small>
    </div>
    <button [disabled]="submitted" class="btn btn-block btn-success" [class.btn-pulse]="submitted">
      Sign In
    </button>
  </form>
</nb-auth-block>