<ng-select [ngModelOptions]="{standalone: true}" name="standards" id="standards" [items]="allGuidelines"
    bindLabel="guidelineID"  [multiple]="true" [searchable]="true" [clearSearchOnAdd]="true" [closeOnSelect]="false"
    [virtualScroll]="true" placeholder="Select" groupBy="provider" [(ngModel)]="selectedGuidelines"
    (change)="guidelineSelected.emit(selectedGuidelines)" (add)="addAnItem($event)" (remove)="removeAnItem($event)">
    <ng-template ng-optgroup-tmp let-item="item" let-index="index">
        <span class="text-black fw-semibold">{{item.provider}}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="custom-control custom-checkbox" [class.ms-4]="item.parentGuidelineID">
            <input id="types-{{index}}" class="custom-control-input" type="checkbox" [ngModel]="item$.selected"
                name="types-{{index}}" [disabled]="item.disabled" />
            <label class="custom-control-label f-small text-neutral-800">{{item.name}}</label>
        </div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
            <span class="ng-value-label">
                @if(item.parentGuidelineID && item.parentGuideline){
                {{item.parentGuideline.name}}/{{item.name}}
                }@else{
                {{item.name}}
                }
            </span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
    </ng-template>
</ng-select>