import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'themeButtonDisplayName'
})
export class ThemeButtonDisplayNamePipe implements PipeTransform {

  themeButtonsDisplayDict = {
    'primary': 'Primary',
    'secondary': 'Secondary',
    'primary-light': 'Primary Light',
  };

  transform(themeButtonValue: string): string {
    if (themeButtonValue in this.themeButtonsDisplayDict) {
      return this.themeButtonsDisplayDict[themeButtonValue];
    }
    return themeButtonValue;
  }

}
