import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'themeColorDisplayNamePipe',
    standalone: false
})
export class ThemeColorDisplayNamePipe implements PipeTransform {

  themeColorsDisplayDict = {
    'white': 'White',
    'neutral-100': 'Neutral 100',
    'neutral-200': 'Neutral 200',
    'neutral-300': 'Neutral 300',
    'neutral-400': 'Neutral 400',
    'neutral-500': 'Neutral 500',
    'neutral-600': 'Neutral 600',
    'neutral-700': 'Neutral 700',
    'neutral-800': 'Neutral 800',
    'neutral-900': 'Neutral 900',

    'blue-100': 'Navy 100',
    'blue-200': 'Navy 200',
    'blue-300': 'Navy 300',
    'blue-400': 'Navy 400',
    'blue-500': 'Navy 500',
    'blue-600': 'Navy 600',
    'blue-700': 'Navy 700',
    'blue-800': 'Navy 800',

    'green-100': 'Green 100',
    'green-200': 'Green 200',
    'green-300': 'Green 300',
    'green-400': 'Green 400',
    'green-500': 'Green 500',
    'green-600': 'Green 600',
    'green-700': 'Green 700',
    'green-800': 'Green 800',

    'yellow-100': 'Yellow 100',
    'yellow-200': 'Yellow 200',
    'yellow-300': 'Yellow 300',
    'yellow-400': 'Yellow 400',
    'yellow-500': 'Yellow 500',
    'yellow-600': 'Yellow 600',
    'yellow-700': 'Yellow 700',
    'yellow-800': 'Yellow 800',

    'red-100': 'Red 100',
    'red-200': 'Red 200',
    'red-300': 'Red 300',
    'red-400': 'Red 400',
    'red-500': 'Red 500',
    'red-600': 'Red 600',
    'red-700': 'Red 700',
    'red-800': 'Red 800',

    'pink-100': 'Pink 100',
    'pink-200': 'Pink 200',
    'pink-300': 'Pink 300',
    'pink-400': 'Pink 400',
    'pink-500': 'Pink 500',
    'pink-600': 'Pink 600',
    'pink-700': 'Pink 700',
    'pink-800': 'Pink 800',

    'm-blueberry-darkest': 'Marketing Blueberry darkest',
    'm-blueberry-dark': 'Marketing Blueberry dark',
    'm-blueberry-default': 'Marketing Blueberry default',
    'm-blueberry-light': 'Marketing Blueberry light',
    'm-blueberry-lightest': 'Marketing Blueberry lightest',

    'm-sky-dark': 'Marketing Sky dark',
    'm-sky-default': 'Marketing Sky default',
    'm-sky-light': 'Marketing Sky light',

    'm-sea-dark': 'Marketing Sea dark',
    'm-sea-default': 'Marketing Sea default',
    'm-sea-light': 'Marketing Sea light',

    'm-leaf-default': 'Marketing Leaf default',
    'm-leaf-light': 'Marketing Leaf light',

    'm-mandarine-dark': 'Marketing Mandarine dark',
    'm-mandarine-default': 'Marketing Mandarine default',
    'm-mandarine-light': 'Marketing Mandarine light',

    'm-cream-dark': 'Marketing Cream dark',
    'm-cream-default': 'Marketing Cream default',
  };

  transform(themeColorValue: string): string {
    if (themeColorValue in this.themeColorsDisplayDict) {
      return this.themeColorsDisplayDict[themeColorValue];
    }
    return themeColorValue;
  }

}
