import { DatePipe, formatDate } from '@angular/common';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export default class Utils {
  // recursively delete empty fields in a given object
  static removeEmptyFields(obj: any): void {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        // Trim string values
        obj[key] = obj[key].trim();
      }

      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key]) && !(obj[key] instanceof Date)) {
        // Recursively clean nested objects
        this.removeEmptyFields(obj[key]);

        // Remove empty objects after recursion
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      } else if (
        obj[key] == null ||
        obj[key] === '' ||
        (Array.isArray(obj[key]) && obj[key].length === 0) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0 && !(obj[key] instanceof Date))

      ) {
        delete obj[key];
      }
    });
  }

  static RemoveSuffix(fileName: string): string {
    const dotIndex = fileName.indexOf('.');
    if (dotIndex !== -1) {
      return fileName.substring(0, dotIndex);
    }
    return fileName;
  }

  static getDateFromToday(isBefore, days) {
    const d = new Date();
    return isBefore ? d.setDate(d.getDate() - (days - 1)) : d.setDate(d.getDate() + (days - 1));
  }

  static colorStatus(status): any {
    if (status) {
      switch (status) {
        case 'Draft':
          return `<div class="draft">${status}</div>`;
        case 'Scheduled':
          return `<div class="scheduled">${status}</div>`;
        case 'Published':
          return `<div class="published">${status}</div>`;
        case 'Commence Review':
          return `<div class="review">${status}</div>`;
        case 'Urgent':
          return `<div class="urgent">${status}</div>`;
        case 'Expired':
          return `<div class="expired">${status}</div>`;
        case 'Error':
          return `<div class="errorStatus">${status}</div>`;
        case 'Archived':
          return `<div class="archived">${status}</div>`;
        default:
          return status;
      }
    }
    return status;
  }

  static checkEmail(relations: any, key: string): any {
    let date;
    let hasValue: boolean = true;
    if (relations) {
      relations.forEach(relation => {
        if (relation.educator.email && relation.educator.email !== '') {
          if (relation.plannerDetail && relation.plannerDetail[key]) {
            date = date ? ((date > relation.plannerDetail[key]) ? date : relation.plannerDetail[key])
              : relation.plannerDetail[key];
          } else {
            hasValue = false;
          }
        }
      });
    } else {
      hasValue = false;
    }
    return hasValue ? date : null;
  }

  //
  static checkOnsiteStaffEmail(relations: any): any {
    let date;
    let hasValue: boolean = true;
    if (relations) {
      relations.forEach(relation => {
        if (relation.onsiteStaff && relation.onsiteStaff.email && relation.onsiteStaff.email !== '') {
          if (relation.onsiteBriefEmailDate) {
            date = date ? ((date > relation.onsiteBriefEmailDate) ? date : relation.onsiteBriefEmailDate)
              : relation.onsiteBriefEmailDate;
          } else {
            hasValue = false;
          }
        }
      });
    } else {
      hasValue = false;
    }
    return hasValue ? date : null;
  }

  static toUTCDayISO(time) {
    const date = new Date(time);
    date.setHours(0);
    return date.toISOString();
  }

  static dateDifference(date1, date2): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.ceil(Math.abs(d1.getTime() - d2.getTime()) / oneDay);
  }

  static convertArrayToCSV(args: any) {
    let result = '';
    const data = args.data || null;
    const lineDelimiter = args.lineDelimiter ? args.lineDelimiter : '\n';
    const title = args.title ? args.title : '';

    if (data == null || !data.length) {
      return null;
    }
    result += title;
    result += lineDelimiter;
    data.forEach(function (item) {
      result += '\"' + item + '\"';
      result += lineDelimiter;
    });

    return result;
  }

  /**
   * @param config: ExportCSVConfig. If keys and headers provided, please make sure the orders of keys and headers are match
   * */

  static exportToCSV(config: ExportCSVConfig) {
    const csv = this._convertArrayOfObjectsToCSV(config);
    if (!csv) {
      return;
    }
    const blob = new Blob([csv], { type: 'text/csv' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, config.fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', config.fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    // const link = document.createElement('a');
    // link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    // link.setAttribute('download', config.fileName);
    // link.click();
  }
  // Export to CSV function
  private static _convertArrayOfObjectsToCSV(args: ExportCSVConfig) {
    let result = '';
    let ctr = 0;
    const dict = [];
    if (args.keys) {
      let weight = 0;
      args.keys.forEach(key => {
        dict[key] = weight;
        weight++;
      });
    }
    const data = args.data || null;

    // If arg.keys provided, the columns in the CSV will be ordered based on the args.keys array provided. Otherwise the
    // order of the columns will be: "combination of the insertion order for strings keys, and ascending order for
    // number-like keys:" of data[0]
    const keys = args.keys ? Object.keys(data[0]).sort((k1, k2) => dict[k1] - dict[k2]) : Object.keys(data[0]);

    const columnDelimiter = args.columnDelimiter ? args.columnDelimiter : ',';
    const lineDelimiter = args.lineDelimiter ? args.lineDelimiter : '\n';
    const title = args.title ? args.title : '';

    if (data == null || !data.length) {
      return null;
    }
    result += title;
    result += lineDelimiter;

    // if (args.headers && args.headers.length === keys.length) {
    //   result += args.headers.join(columnDelimiter);
    // } else {
    //   result += keys.join(columnDelimiter);
    // }
    result += args.headers.join(columnDelimiter);

    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;

      if (args.useHeadersAsKey) {
        args.headers.forEach(function (key) {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          // avoid treating the comma inside each item treat as columnDelimiter.
          const temp = (item[key] || item[key] === 0) ? item[key] : '';
          result += '\"' + temp + '\"';
          ctr++;
        });

      } else {
        keys.forEach(function (key) {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          // avoid treating the comma inside each item treat as columnDelimiter.
          let temp = item[key] == null ? '' : item[key];
          if (item[key] instanceof Array && item[key].length > 1) {
            temp = '';
            item[key].forEach(ele => {
              temp = temp + ele + '\n';
            });
          }
          // if (key === 'financialComment') {
          //   temp = temp.replace(/\n/g, '\r');
          //   // temp = temp.replace(/#/g, '');
          // }
          result += '"' + temp + '"';
          ctr++;
        });
      }

      result += lineDelimiter;
    });

    return result;
  }

  static beautifyDate(date): string {
    if (date) {
      const raw = new Date(date);
      return formatDate(raw, 'dd MMM yyyy', 'en-au');
    } else {
      return date;
    }
  }

  static decodeEntities(encodedString) {
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
      'nbsp': ' ',
      'amp': '&',
      'quot': '"',
      'lt': '<',
      'gt': '>',
    };
    return encodedString.replace(translate_re, (match, entity) => {
      return translate[entity];
    }).replace(/&#(\d+);/gi, ' ');
  }

  static textToLink(title: string, resourceID: string, activityType: string, providerAlias?: string) {
    if (resourceID === 'N/A') {
      return title;
    } else {
      let baseUrl = (environment.production) ? 'https://www.ausmed.com.au/' : environment.ausmedSite;
      switch (activityType) {
        case 'blog':
          baseUrl += 'cpd/articles/' + resourceID + '/view';
          break;
        case 'explainer':
          baseUrl += 'cpd/explainers/' + resourceID;
          break;
        case 'lecture':
          baseUrl += 'cpd/lecture/' + resourceID;
          break;
        case 'OnlineCourse':
          baseUrl += 'cpd/courses/' + resourceID;
          break;
        case 'guides':
          baseUrl += 'cpd/guides/' + resourceID;
          break;
        case 'thirdParty':
          if (providerAlias) {
            baseUrl += 'cpd/providers/' + providerAlias + '/' + resourceID;
          }
          break;
        default:
          baseUrl = resourceID;
          break;
      }
      return '=HYPERLINK(""' + baseUrl + '""\,""' + title + '"")';
    }
  }

  static flattenNestedObject(obj): any {
    const flattened = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, this.flattenNestedObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });
    return flattened;
  }

  static validateHtmlFactory(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const doc = document.createElement('div');
      doc.innerHTML = control.value;
      const isValid = (doc.innerHTML === control.value);
      if (isValid) {
        return null;
      } else {
        return {
          validateHTML: {
            valid: false,
          },
        };
      }
    };
  }

  // Handle non-exception-throwing cases:
  // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
  // but... JSON.parse(null) returns null, and typeof null === "object",
  // so we must check for that, too. Thankfully, null is falsey, so this suffices:
  static tryParseJSON(jsonString) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) { }
    return false;
  }

  /**
   * This function is used for valuePrepareFunction in ng2-smart-table setting
   * Transfer the date into dd/MM/yyyy format
   */
  static dateValuePrepareFunction(datePipe: DatePipe, date){
    if (date) {
      const raw = new Date(date);
      return datePipe.transform(raw, 'dd/MM/yyyy');
    } else {
      return date;
    }
  }

  /**
   * This function is used for filterFunction in ng2-smart-table setting
   * Check the if the date matched
   */
  static dateFilterFunction(datePipe: DatePipe, date: string, search?: string): boolean {
    if (!search) {
      return true
    }
    const transformed = datePipe.transform(date, 'dd/MM/yyyy');
    const match = transformed ? transformed.includes(search) : false;
    return match;
  }
}

export interface ExportCSVConfig {
  data: any[];
  headers?: string[];
  keys?: string[];
  useHeadersAsKey: boolean;
  title: string;
  fileName: string;
  columnDelimiter?: string;
  lineDelimiter?: string;
}


