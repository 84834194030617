import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ThirdPartyResourceTypePipe',
  standalone: true
})
export class ThirdPartyResourceTypePipe implements PipeTransform {
  typesDict = {
    article: 'Article',
    video: 'Video',
    OnlineCourse: 'Course',
    practical: 'Practical activity',
    podcast: 'Podcast',
    webinar: 'Webinar',
    other: 'Other',
    SCORM: 'SCORM'
  };
  transform(value: any, ...args: any[]): any {
    return this.typesDict[value] || value;
  }

}
