import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProfessionsService {

  constructor(
    protected http: HttpClient,
  ) { }

  getProfessions(): Observable<any> {
    const url = environment.accountServiceEndpoint + '/quals';
    return this.http.get(url);
  }

  getRegulation(boardID): Observable<any> {
    const url = environment.accountServiceEndpoint + '/boards/' + boardID + '/regulations';
    return this.http.get(url);
  }

  getBoard(): Observable<any> {
    const url = environment.accountServiceEndpoint + '/boards';
    return this.http.get(url);
  }

  getQuals(board): Observable<any> {
    const url = environment.accountServiceEndpoint + '/quals?board=' + board.boardID;
    return this.http.get(url);
  }

}

