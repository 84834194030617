import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Utils from '../../@core/utils/utils';

@Component({
    selector: 'ngx-search-user-modal',
    templateUrl: './search-user-modal.component.html',
    styleUrls: ['./search-user-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SearchUserModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal,
              private router: Router,
              private ToastrService: ToastrService) { }

  user;

  ngOnInit() {
    this.user = {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      mobile: '',
      phone: '',
    };
  }

  closeModal() {
    this.activeModal.close();
  }

  searchUser() {
    Utils.removeEmptyFields(this.user);
    this.router.navigate(['/pages/operations/customer-search'], { queryParams: this.user });
    this.closeModal();
  }


}
