import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbPopoverModule, NbCheckboxModule, NbTabsetModule, NbRadioModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { NgxLoginComponent } from './components/auth/login/login.component';
import { NgxLogoutComponent } from './components/auth/logout/logout.component';

import {
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { NbAuthModule } from '@nebular/auth';
import { TitleToAliasPipe } from './pipes/titleToAlias.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StatePipe } from './pipes/state.pipe';
import { ThirdPartyResourceTypePipe } from './pipes/third-party-resource-type.pipe';
import { EventTypePipe } from './pipes/event-type.pipe';
import { RouterModule } from '@angular/router';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];


const NB_MODULES = [
  NbActionsModule,
  NbCardModule,
  NbCheckboxModule,
  NbRadioModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbPopoverModule,
  NbEvaIconsModule,
];
const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  NgxLogoutComponent,
  NgxLoginComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  TitleToAliasPipe,
];

@NgModule({
  imports: [BASE_MODULES, ...NB_MODULES, NbAuthModule, RouterModule, ThirdPartyResourceTypePipe],
  exports: [BASE_MODULES, ...NB_MODULES, ...PIPES, ...COMPONENTS, StatePipe, EventTypePipe, ThirdPartyResourceTypePipe],
  declarations: [...COMPONENTS, ...PIPES, StatePipe, EventTypePipe],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
